import React from 'react';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

const Podcast = () => {
    return (
        <div className="page-container">
        <Navigation />
     <h1>Coming soon...</h1>
     <Footer />   
    </div>
    );
};

export default Podcast;